import './App.css';
import background from "./img/air-conditioner-83.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <section className="welcome">
        <h1>Klímaszerelő</h1>
        <h2>
          Klímaszerelés, klímatelepítés,<br />klímatisztítás, klímakarbantartás
        </h2>
        <div className="grad1"></div>
      </section>
      <section className="info">
        <h2 className="infotxt">Hűtő- és fűtő klímaberendezések szervizelése, telepítése, karbantartása, javítása</h2>
      </section>
      <section className="home-description">
        <p className="infotxt"><strong>Klímaszerelés</strong> és karbantartás terén a legmagasabb minőséget és megbízhatóságot kínáljuk ügyfeleinknek. Tapasztalt klímaszerelő szakembereink gondoskodnak arról, hogy az Ön klímaberendezései mindig tökéletesen működjenek.</p>
        <p className="infotxt">Klímaszerviz szolgáltatásunk segítségével gyorsan és hatékonyan javítjuk és karbantartjuk légkondicionálóit. Az általunk végzett <strong>klímatelepítés</strong> során figyelembe vesszük az Ön igényeit és az adott helyszín sajátosságait, hogy optimális légkondicionáló rendszert telepítsünk.</p>
        <p className="infotxt">Klímakarbantartás szolgáltatásunkkal biztosítjuk, hogy klímái biztonsággal, hosszú élettartammal és maximális hatékonysággal üzemeljenek. A <strong>légkonícionálók szervizelése</strong> során alaposan ellenőrizzük a rendszer teljesítményét és tisztítjuk a szükséges alkatrészeket.</p>
        <p className="infotxt">Légkondiszerelőink szakértelmével és tapasztalatával igyekszünk a felmerülő problémákat gyorsan és hatékonyan megoldani. Legyen szó légkondiszervizről, <strong>klímatisztításról</strong> vagy légkonícionálók javításáról, mi állunk rendelkezésére.</p>
        <p className="infotxt">Légkonícionálók telepítése során precízen és gondosan végezzük el a munkát, hogy Önnek a legoptimálisabb klímamegoldást nyújtsuk. Emellett légkondi tisztítási és karbantartási szolgáltatásaink segítségével biztosítjuk, hogy klímái mindig frissek és tiszták legyenek.</p>
      </section>
      <section className="contact">
        <FontAwesomeIcon icon={faPhone} className="phone-icon" />
        <span className="phonenr">+36 30 559 8349</span>
      </section>
    </div>
  );
}

export default App;
